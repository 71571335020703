export const currency: string ="MATIC";
export const token: string ="TCK";
export const addressToken: string ="0x445717b9092727120254E881AfC1a9ce7797a20F";                                    
export const game: string ="https://play.catfarmcrypto.com/";
export const wiki: string ="https://whitepaper.catfarmcrypto.com/";
export const discord: string ="https://discord.gg/KMVeZqwt";
export const twitter: string ="";
export const instagram: string ="";
export const facebook: string ="";
// import { currency,token,addressToken,game,wiki,discord,twitter,instagram,facebook } from '../../../constants';
